import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { debounce } from "lodash";
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import axios from 'axios';
const app = createApp(App)
const resizeObserver  = (window).ResizeObserver;
(window).ResizeObserver = class ResizeObserver extends resizeObserver  {
  constructor(callback) {
    callback = debounce(callback, 100);
    super(callback);
  }
}
app.use(ElementPlus,{
    locale: zhCn,
  })
app.config.globalProperties.$http = axios
app.mount('#app')

