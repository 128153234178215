<template>
  <h2>吾圈加拿大幸运5机器人</h2>
  <el-row :gutter="20" style="width: 100%;margin: 0 auto;" v-show="!showcz">
    <el-col :span="8">
    </el-col>
    <el-col :span="8">
      <div class="grid-content ep-bg-purple" />
      <el-form label-width="auto" label-position="Right" size="large">
        <el-form-item label="手机号">
          <el-input v-model="userName" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="password" type="password" />
        </el-form-item>
        <el-form-item label="服务器id">
          <el-input v-model="serverId" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100%;">登录</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="8"></el-col>
  </el-row>
    <el-row :gutter="20" style="width: 100%;margin: 0 auto;" v-show="showcz">
    <el-col :span="12">
      <el-button type="primary" @click="onks">开始统计</el-button>
      <el-table
        ref="multipleTableRef"
        :data="groupList"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
      <el-table-column type="selection"  />
        <el-table-column property="groupID" label="群id"   />
        <el-table-column property="name" label="群名称"  />
      </el-table>
    </el-col>
    <el-col :span="12">
      <el-table :data="tableData" style="width: 100%" max-height='400'>
        <el-table-column prop="drawIssue" label="开奖期号"  />
        <el-table-column prop="drawCode" label="开奖结果"  />
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios'
import { ElMessage } from 'element-plus'
import TencentCloudChat from '@tencentcloud/chat';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import myWorker from "worker-loader!./worker.js"

export default {
  name: 'App',
  data() {
    return {
      sizeForm: [],
      userName: '',
      serverId: '',
      password: '',
      accid: '',
      imappid: 0,
      token: '',
      chat: null,
      showcz: false,
      kj: [],
      issue:'',
      nextiusse: '',
      disabled: true,
      groupList: [],
      xuanGroupList: [],
      Crypto: CryptoJS.enc.Utf8.parse("666888\0\0\0\0\0\0\0\0\0\0"),
      dTime:0,
      Worker:null,
      isks:false,
    }
  },
  components: {
    

  },
  computed:{
    tableData(){
      let day =  moment().format('YYYY-MM-DD')
      let daykj = this.kj.filter(item=>item.drawTime.includes(day))
      return daykj
    }
  },
  created(){

  },
  watch: {

  },
  mounted() {
    this.getkj()
    this.Worker = new myWorker()
    this.Worker.postMessage({
        type: "start",
        interval: 1000
    })
    this.Worker.onmessage = (event) => {
        // 收息消息
        if (event.data.type == 'message') {
          this.dTime--;
          if(parseInt(this.dTime)<=0 && parseInt(this.dTime)%5==0){
            // 封盘
            this.getkj()
          }
        }
      }
  },
  methods: {
    handleSelectionChange(item){
      this.xuanGroupList = item
    },
    // 函数
    onks(){
      this.isks = true
    },
    getkj(){
      axios.get('https://jqr.ts28.co/getjndxy5').then(res=>{
        if(res.data.msg == 'ok'){
          this.kj = res.data.data.list
          this.issue = this.kj[0].drawIssue
          if(this.issue != this.nextiusse){
            // 是开奖
            // 处理开奖后自动发送文本
            let now = moment(this.kj[0].drawTime).add(210,'seconds').format('YYYY-MM-DD HH:mm:ss')
            this.dTime  = moment(now).unix()-moment().unix()
            this.nextiusse = this.issue
            this.sendmsg()
          }
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    onSubmit() {
      // 登录
      let data = {
        password: this.password,
        serverId: this.serverId,
        userName: this.userName
      }
      let login = axios.create({
        baseURL: 'https://jqr.ts28.co',
        timeout: 5000, // 请求超时时间
      })
      login.post('/wqlogin', data).then(res => {
        if (res.data.code == 200) {
          //登录成功
          ElMessage({
            message: res.data.msg,
            type: 'success',
          })
          this.accid = res.data.data.accid
          this.toekn = res.data.data.token
          this.imappid = parseInt(res.data.data.imAppid)
          // 登录腾讯im
          let options = {
            SDKAppID: parseInt(res.data.data.imAppid)
          }
          this.chat = TencentCloudChat.create(options);
          let promise = this.chat.login({ userID: res.data.data.accid, userSig: res.data.data.token });
          promise.then((imResponse) => {
            ElMessage({
              message: '连接服务器成功',
              type: 'success',
            })
            setTimeout(() => {
              this.chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, this.getMsg)
              this.chat.getGroupList().then(imResponses => {
                this.groupList = imResponses.data.groupList
                this.showcz = true
              }).catch(imError => {
                console.warn('getGroupList error:', imError); // 获取群组列表失败的相关信息
              })
            }, 1000)
            if (imResponse.data.repeatLogin === true) {
              // 标识账号已登录，本次登录操作为重复登录。
              console.log(imResponse.data.errorInfo);
              ElMessage({
                message: '重复登录，请退出其他页面登录后再试',
                type: 'error',
              })
            }
          }).catch((imError) => {
            console.log(imError)
            ElMessage({
              message: '连接服务器失败请重试',
              type: 'error',
            })
          });
        } else {
          //登录失败
          ElMessage({
            message: res.data.msg,
            type: 'error',
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    sendmsg(){
      if(this.isks){
        let remsg = this.kj[0].drawIssue+'期开奖结果为：'+'\n'+this.kj[0].drawCode + '  '+this.getDx(this.kj[0].drawCode)+'  '+this.getDs(this.kj[0].drawCode)+'  '+this.getlonghu(this.kj[0].drawCode)
        remsg = remsg+'\n'+'===================='
        remsg = remsg+'\n'+'历史往期开奖：'
        for (let index = 1; index < 11; index++) {
          remsg = remsg+'\n'+this.kj[index].drawIssue+'期:'+this.kj[index].drawCode
        }

        for (let y = 0; y < this.xuanGroupList.length; y++) {
          let messages =  this.chat.createTextMessage({
                    to:this.xuanGroupList[y].groupID,
                    conversationType:TencentCloudChat.TYPES.CONV_GROUP,
                    payload: {
                      text: remsg
                    },
              })
              let promisesend = this.chat.sendMessage(messages);
              promisesend.then(function(imResponse) {
                // 发送成功
                console.log(imResponse);
              }).catch(function(imError) {
                // 发送失败
                console.log('sendMessage error:', imError);
              })
        }
      }
    },
    getMsg(event) {
      const messageList = event.data;
      messageList.forEach((message) => {
        if (message.type === TencentCloudChat.TYPES.MSG_TEXT) {
          let msg = this.textPa(message.payload.text)
          let xuanzhonglist = this.xuanGroupList.filter( item=> item.groupID === message.to)
          if(xuanzhonglist.length!=0){
            // 有群
            if(msg == 'jndxy5今日号码统计'){
              let day =  moment().format('YYYY-MM-DD')
              let daykj = this.kj.filter(item=>item.drawTime.includes(day))
              let kjtongjinum = this.getnumnum(daykj)
              let kjtongjilonghu = this.getarrlonghu(daykj)
              let kjtongjidx = this.getarrdaxiao(daykj)
              let kjtongjids = this.getarrdanshuang(daykj)
              let remsg = '今日已开奖：'+daykj.length+'期'

              for (let index = 0; index < kjtongjinum.length; index++) {
                if(index ==0){
                  remsg = remsg+'\n'+'第一球:'
                }
                if(index ==1){
                  remsg = remsg+'\n'+'第二球:'
                }
                if(index ==2){
                  remsg = remsg+'\n'+'第三球:'
                }
                if(index ==3){
                  remsg = remsg+'\n'+'第四球:'
                }
                if(index ==4){
                  remsg = remsg+'\n'+'第五球:'
                }  
                for (let y = 0; y < kjtongjinum[index].length; y++) {
                  if(y%2 != 0){
                    continue;
                  }else{
                    remsg = remsg+'\n'+y+':'+kjtongjinum[0][y]+'次    '+(y+1)+':'+kjtongjinum[0][y+1]+'次'
                  }
                }
              }
              remsg = remsg+'\n'+'大：'+kjtongjidx[0]+'次     ' +'小：'+kjtongjidx[1]+'次' 
              remsg = remsg+'\n'+'单：'+kjtongjids[0]+'次     ' +'双：'+kjtongjids[1]+'次' 
              remsg = remsg+'\n'+'龙：'+kjtongjilonghu[0]+'次   ' +'虎：'+kjtongjilonghu[1]+'次   ' +'合：'+kjtongjilonghu[2]+'次'

              let messages =  this.chat.createTextMessage({
                    to:message.to,
                    conversationType:TencentCloudChat.TYPES.CONV_GROUP,
                    payload: {
                      text: remsg
                    },
              })
              let promisesend = this.chat.sendMessage(messages);
              promisesend.then(function(imResponse) {
                // 发送成功
                console.log(imResponse);
              }).catch(function(imError) {
                // 发送失败
                console.log('sendMessage error:', imError);
              })

            }else if(msg == '关闭jndxy5'){
              this.isks = false
              let messages =  this.chat.createTextMessage({
                    to:message.to,
                    conversationType:TencentCloudChat.TYPES.CONV_GROUP,
                    payload: {
                      text: '关闭成功'
                    },
              })
              let promisesend = this.chat.sendMessage(messages);
              promisesend.then(function(imResponse) {
                // 发送成功
                console.log(imResponse);
              }).catch(function(imError) {
                // 发送失败
                console.log('sendMessage error:', imError);
              })
            }else if(msg == '开启jndxy5'){
              this.isks = true
              let messages =  this.chat.createTextMessage({
                    to:message.to,
                    conversationType:TencentCloudChat.TYPES.CONV_GROUP,
                    payload: {
                      text: '开启成功'
                    },
              })
              let promisesend = this.chat.sendMessage(messages);
              promisesend.then(function(imResponse) {
                // 发送成功
                console.log(imResponse);
              }).catch(function(imError) {
                // 发送失败
                console.log('sendMessage error:', imError);
              })
            }else if(msg === '开奖'){
              this.sendmsg()
            }
          }
        }
      })
    },
    textPa(bastr) {
      let srcs = bastr;
      let decrypt = CryptoJS.AES.decrypt(srcs, this.Crypto, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return decrypt.toString(CryptoJS.enc.Utf8)
    },
    getDx(str){
      let strarr = str.split(',')
      let yy = 0
      for (let index = 0; index < strarr.length; index++) {
        yy = yy+parseInt(strarr[index])
      }
      if(yy<23){
        return '大'
      }else{
        return '小'
      }
    },
    getDs(str){
      let strarr = str.split(',')
      let yy = 0
      for (let index = 0; index < strarr.length; index++) {
        yy = yy+parseInt(strarr[index])
      }
      if(yy%2 === 0){
        return '双'
      }else{
        return '单'
      }
    },
    getlonghu(str){
      let strarr = str.split(',')
      if(parseInt(strarr[0])>parseInt(strarr[4])){
        return '龙'
      }else if(parseInt(strarr[0])<parseInt(strarr[4])){
        return '虎'
      }else if(parseInt(strarr[0])===parseInt(strarr[4])){
        return '合'
      }
    },
    getnumnum(kjarr){
      let numarr = []
      for (let y = 0; y < 5; y++) {
        numarr[y] = []
        for (let index = 0; index < 10; index++) {
          let count = kjarr.filter(item=>{
            let strarr = item.drawCode.split(',')
            return parseInt(strarr[y]) === index
          })
          numarr[y][index] = count.length
        }
      }
      return numarr;
    },
    getarrlonghu(kjarr){
      let longhu = []
      let longhucout = []
      for (let index = 0; index < kjarr.length; index++) {
        let longhustr = this.getlonghu(kjarr[index].drawCode)
        if(longhustr == '龙'){
          longhu.push('龙')
        } 
        if(longhustr == '虎'){
          longhu.push('虎')
        } 
        if(longhustr == '合'){
          longhu.push('合')
        }
      }
      longhucout[0] = longhu.filter(item=>item === '龙').length
      longhucout[1] = longhu.filter(item=>item === '虎').length
      longhucout[2] = longhu.filter(item=>item === '合').length
      return longhucout;
    },
    getarrdaxiao(kjarr){
      let longhu = []
      let longhucout = []
      for (let index = 0; index < kjarr.length; index++) {
        let longhustr = this.getDx(kjarr[index].drawCode)
        if(longhustr == '大'){
          longhu.push('大')
        } 
        if(longhustr == '小'){
          longhu.push('小')
        } 
      }
      longhucout[0] = longhu.filter(item=>item === '大').length
      longhucout[1] = longhu.filter(item=>item === '小').length
      return longhucout;
    },
    getarrdanshuang(kjarr){
      let longhu = []
      let longhucout = []
      for (let index = 0; index < kjarr.length; index++) {
        let longhustr = this.getDs(kjarr[index].drawCode)
        if(longhustr == '单'){
          longhu.push('单')
        } 
        if(longhustr == '双'){
          longhu.push('双')
        } 
      }
      longhucout[0] = longhu.filter(item=>item === '单').length
      longhucout[1] = longhu.filter(item=>item === '双').length
      return longhucout;
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 75%;
  margin: 0 auto;
}

</style>

